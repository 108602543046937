import PropTypes from 'prop-types';
import { useEffect, useCallback, useMemo, useState } from 'react';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';
//
import { useUserStore } from '../../../stores/store';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: false,
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const user = useUserStore((state) => state.user)
  const updateUser = useUserStore(state => state.updateUser);
  const removeUser = useUserStore(state => state.removeUser);
  const [state, updateState] = useState(initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        const refreshToken = localStorage.getItem('refreshToken');
        setSession(accessToken, refreshToken);

        updateUser(user)
      } else {
        updateUser(null)
      }
    } catch (error) {
      console.error(error);
      updateUser(null)
    }

  }, [user, updateUser]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const { data } = await axios.post(API_ENDPOINTS.auth.login, { email, password });
    setSession(data.token, data.refreshToken);
    updateUser(data.user);
  }, [updateUser]);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const bodyData = {
      email,
      password,
      firstName,
      lastName,
    };

    const { data } = await axios.post(API_ENDPOINTS.auth.register, bodyData);
    localStorage.setItem(STORAGE_KEY, data.accessToken);
    updateUser(data.user);
  }, [updateUser]);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    removeUser();
  }, [removeUser]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
