import { sum } from 'lodash'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

/*
export const useUserStore = create((set) => ({
  user: null,
  updateUser: (newUser) => set({ user: newUser }),
  removeUser: () => set({ user: null })
}))
*/

export const useUserStore = create(
  persist(
    (set, get) => ({
      user: null,
      updateUser: (dataUser) => set({ user: dataUser }),
      removeUser: () => set({ user: null })
    }),
    {
      name: 'user-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
)

export const useSalesStore = create(
  persist(
    (set, get) => ({
      sales: [],
      total: 0,
      addSale: (newSale) => set((currentState) => ({
        sales: [
          ...currentState.sales,
          newSale,
        ]
      })),
      updateSales: (saleList) => set({ sales: saleList }),
      removeSale: (saleId) => set((currentState) => ({
        sales: currentState.sales.filter(item => item.id !== saleId)
      })), // sales.filter(item => item._id !== saleId)
      removeAllSales: () => set({ sales: [] }),
      increaseQuantity: (productId) => set((currentState) => increaseQty(productId, currentState.sales)),
      decreaseQuantity: (productId) => set((currentState) => decreaseQty(productId, currentState.sales))
    }),
    {
      name: 'sales-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
)

const increaseQty = (productId, sales) => {
  const newSales = sales.map((product) => {
    if (product.id === productId) {
      return {
        ...product,
        quantity: product.quantity + 1,
      };
    }
    return product;
  });
  return { sales: newSales }
}

const decreaseQty = (productId, sales) => {
  const newSales = sales.map((product) => {
    if (product.id === productId) {
      return {
        ...product,
        quantity: product.quantity - 1,
      };
    }
    return product;
  });
  return { sales: newSales }
}